.content-bot{
    padding-left: 5px;
    padding-right: 5px;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
}

.content-image-bot{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    height: 40px;
    min-width: 40px;
    transform-origin: right bottom;
    animation: 0.3s ease 0s 1 normal forwards running Lmuha;
    border-radius: 50% 50% 0px;
    padding: 3px;
    margin: 3px;
}